import { DASHBOARDFIELDS, UNIQUEHEADERSTRING } from "src/utils/constants";
import { QueryResponseProps, PageState } from "../../interfaces/types";
import { useFetchItemRequestsQuery } from "src/Redux/Slice/Api/QuerySlice";
import Table from "../shared/Table/Table";
import { useNavigate } from "react-router-dom";
import { EDIT_REQUEST_ROUTE } from "src/utils/routesConstant";

const initialPageState: PageState = { skip: 0, take: 100 };

const Dashboard = () => {
  const { data, isLoading, error } =
    useFetchItemRequestsQuery<QueryResponseProps>();
  const navigate = useNavigate();

  const initialPageSort = [
    {
      field: UNIQUEHEADERSTRING,
      dir: "asc" as const,
    },
  ];

  return (
    <>
      <Table
        data={data}
        isLoading={isLoading}
        error={error}
        reqFields={DASHBOARDFIELDS}
        initialPageState={initialPageState}
        initialPageSort={initialPageSort}
        gridSkeletonRowCount={100}
        exportFileName="GESupplierPortalRequests"
        {...(!(import.meta.env.VITE_NODE_ENV !== "development") && {
          onRowClick: (e) => {
            navigate(
              `${EDIT_REQUEST_ROUTE}/${
                e.dataItem.requestNumber
              }/${e.dataItem.itemType.split(" ").join("-")}`
            );
          },
        })}
      />
    </>
  );
};

export default Dashboard;
