import { EDIT_BREADCRUMBS, EDIT_BREADCRUMBS_ENUM } from "src/utils/constants";
import Breadcrumbs from "../shared/breadcrumbs/breadcrumbs";
import { useParams } from "react-router";
import { useState } from "react";
import { EditShipper } from "./EditShipper";
import { EditItem } from "./EditItem";
import { EditItemDetail } from "./EditItemDetail";
import { EditReview } from "./EditReview";
import { EditRequest } from "./EditRequest";
import { BreadCrumbModel } from "src/interfaces/types";

export const EditRequestDashboard = () => {
  const { requestType, requestId } = useParams();
  const [selectedBreadCrumb, setSelectedBreadCrumb] = useState<string>("");

  const initialBreadCrumbArray = [
    {
      id: requestId ?? "",
      text:
        requestType
          ?.split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ") ?? "",
      disabled: true,
    },
    EDIT_BREADCRUMBS[0],
  ];

  const [breadcrumbDataArray, setBreadcrumbDataArray] = useState<
    BreadCrumbModel[]
  >(initialBreadCrumbArray);

  /**
   * @description - This function renders the form component based on the selected breadcrumb
   *  @returns {JSX.Element} - Form component based on the selected breadcrumb
   */
  const renderFormComponent = (): JSX.Element => {
    switch (selectedBreadCrumb) {
      case EDIT_BREADCRUMBS_ENUM.SHIPPER:
        return (
          <EditShipper
            setBreadcrumbDataArray={setBreadcrumbDataArray}
            setSelectedBreadCrumb={setSelectedBreadCrumb}
          />
        );
      case EDIT_BREADCRUMBS_ENUM.ITEM:
        return (
          <EditItem
            setBreadcrumbDataArray={setBreadcrumbDataArray}
            setSelectedBreadCrumb={setSelectedBreadCrumb}
          />
        );

      case EDIT_BREADCRUMBS_ENUM.ITEM_DETAIL:
        return (
          <EditItemDetail
            setBreadcrumbDataArray={setBreadcrumbDataArray}
            setSelectedBreadCrumb={setSelectedBreadCrumb}
          />
        );
      case EDIT_BREADCRUMBS_ENUM.REVIEW:
        return <EditReview />;
      default:
        return (
          <EditRequest
            setBreadcrumbDataArray={setBreadcrumbDataArray}
            setSelectedBreadCrumb={setSelectedBreadCrumb}
          />
        );
    }
  };

  return (
    <div>
      <Breadcrumbs
        data={breadcrumbDataArray}
        onBreadcrumbClick={(e) => {
          setSelectedBreadCrumb(
            (e.target as HTMLButtonElement).textContent ?? ""
          );
        }}
        activeBreadCrumb={selectedBreadCrumb}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("submitting the form");
        }}
      >
        {renderFormComponent()}
      </form>
    </div>
  );
};
