import Dashboard from "components/dashboard/dashboard";
import { CreateRequest } from "components/create-request/createRequest";
import { UpdateRequest } from "components/update-request/updateRequest";

import {
  CREATE_REQUEST_ROUTE,
  EDIT_REQUEST_ROUTE,
  DASHBOARD_ROUTE,
  UPDATE_REQUEST_ROUTE,
} from "src/utils/routesConstant";
import { EditRequestDashboard } from "src/components/editRequest/EditRequestDashboard";

export const NAVIGATION_ITEMS = [
  {
    label: "Dashboard",
    path: DASHBOARD_ROUTE,
    component: <Dashboard />,
  },
  {
    label: "New Item Request",
    path: CREATE_REQUEST_ROUTE,
    component: <CreateRequest />,
  },
  {
    label: "Update Item",
    path: UPDATE_REQUEST_ROUTE,
    component: <UpdateRequest />,
    disabled: import.meta.env.VITE_NODE_ENV !== "development",
  },
];

export const ROUTES = [
  {
    label: "Edit Request",
    path: `/${EDIT_REQUEST_ROUTE}/:requestId/:requestType`,
    component: <EditRequestDashboard />,
    disabled: import.meta.env.VITE_NODE_ENV !== "development",
  },
  ...NAVIGATION_ITEMS,
];
