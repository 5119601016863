import { EditFormComponentProps } from "src/interfaces/types";
import { EDIT_BREADCRUMBS, EDIT_BREADCRUMBS_ENUM } from "src/utils/constants";
import { removeArrayDuplicates } from "src/utils/editRequestUtils";

export const EditShipper = ({
  setBreadcrumbDataArray,
  setSelectedBreadCrumb,
}: EditFormComponentProps) => {
  return (
    <div>
      <h1>Shipper Request Form</h1>
      <button
        type="button"
        onClick={() => {
          // setting next breadcrumb data to Item edit
          setSelectedBreadCrumb(EDIT_BREADCRUMBS_ENUM.ITEM);
          setBreadcrumbDataArray((prev) =>
            removeArrayDuplicates(prev, EDIT_BREADCRUMBS[2])
          );
        }}
      >
        Next
      </button>
    </div>
  );
};
