import { Breadcrumb, BreadcrumbLinkProps } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { color } from "src/styles/_colors";
import { BodyBold14 } from "src/styles/_typography";
import { BreadCrumbModel } from "src/interfaces/types";

interface BreadcrumbsProps {
  data: BreadCrumbModel[];
  activeBreadCrumb?: string;
  onBreadcrumbClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const Breadcrumbs = ({
  data,
  onBreadcrumbClick,
  activeBreadCrumb,
}: BreadcrumbsProps) => {
  const defaultActiveText = data.find((item) => !item?.disabled)?.text || "";
  const [currentActiveBreadcrumb, setCurrentActiveBreadcrumb] =
    useState<string>();

  useEffect(() => {
    setCurrentActiveBreadcrumb(activeBreadCrumb || defaultActiveText);
  }, [activeBreadCrumb, defaultActiveText]);

  // Custom renderer for each breadcrumb link
  const renderBreadcrumbLink = (props: BreadcrumbLinkProps) => {
    const isActive = props.text === currentActiveBreadcrumb;

    const handleClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (!props?.disabled) {
        setCurrentActiveBreadcrumb(props.text ?? "");
        onBreadcrumbClick(e);
      }
    };
    return (
      <StyledBreadcrumbButton
        disabled={props?.disabled}
        aria-current={isActive ? "true" : undefined}
        style={isActive ? activeStyles : undefined}
        onClick={(e) => handleClick(e)}
      >
        {props.text}
      </StyledBreadcrumbButton>
    );
  };
  return (
    <Container>
      <Breadcrumb
        data={data}
        textField="text"
        breadcrumbLink={renderBreadcrumbLink}
      />
    </Container>
  );
};
export default Breadcrumbs;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
`;

const StyledBreadcrumbButton = styled.button`
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: ${color.primary};
  ${BodyBold14};

  &:hover {
    color: ${color.gray30};
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${color.primary};
    text-decoration: none;
  }
`;

const activeStyles = {
  color: color.gray30,
  background: color.gray90,
};
