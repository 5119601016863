import { useEffect, useState } from "react";
import DialogBox from "../dialogBox/dialogBox";
import { useErrorContext } from "src/contexts/ErrorContext";
import styled from "styled-components";
import { SvgIcon } from "@progress/kendo-react-common";
import { warningTriangleIcon } from "@progress/kendo-svg-icons";
import { color } from "src/styles/_colors";
import { Body14 } from "src/styles/_typography";

const ErrorTitle = () => (
  <ErrorTitleStyled>
    <SvgIcon icon={warningTriangleIcon} />
    Error
  </ErrorTitleStyled>
);
const ErrorFallbackPage = ({ message }: Record<string, string>) => {
  const [shouldShow, setShouldShow] = useState(false);
  const { errorMessage } = useErrorContext();

  useEffect(() => {
    setShouldShow(true);
  }, []);

  return shouldShow ? (
    <DialogBox isOpen={shouldShow} title={<ErrorTitle />} closeIcon={false}>
      <>
        <ErrorPStyled>{errorMessage || message}</ErrorPStyled>
        <StyledUl>
          <li>US: 1-888-826-3193 or ext: 20200</li>
          <li>International: 000-800-040-4315</li>
        </StyledUl>
      </>
    </DialogBox>
  ) : null;
};

export default ErrorFallbackPage;

const StyledUl = styled.ol`
  list-style-type: lower-roman;
  ${Body14};
`;

const ErrorTitleStyled = styled.p`
  color: ${color.primary};
  display: flex;
  margin: 0;
  > .k-icon {
    margin-right: 10px;
    align-self: center;
  }
`;

const ErrorPStyled = styled.p`
  ${Body14};
  margin: 0;
`;
