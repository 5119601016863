import { GridCellProps } from "@progress/kendo-react-grid";
import { parseDate } from "src/utils/dataUtils";
import { FieldTypeProps } from "./type";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import SelectDropdown from "../selectDropdown/selectDropdown";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { SvgIcon } from "@progress/kendo-react-common";
import { warningTriangleIcon } from "@progress/kendo-svg-icons";
import {
  ItemResponse,
  ItemType,
  RItemResponse,
  WhItemResponse,
} from "src/interfaces/types";
interface TableColCellProps extends GridCellProps {
  isCustomFields?: FieldTypeProps;
  inEdit?: boolean;
  isEditable: boolean;
  data: (ItemResponse | WhItemResponse | RItemResponse)[];
  itemType?: ItemType;
}

const TableColCell = ({
  className,
  isCustomFields,
  inEdit,
  isEditable,
  data,
  itemType,
  ...props
}: TableColCellProps) => {
  const value = (props.field && props.dataItem[props.field]) || "";
  const customFields = isCustomFields;
  const title = customFields?.fieldType === "date" ? "" : value;
  const field = itemType === "retail" ? "rItem" : "whitem";

  const rowAvailable =
    itemType &&
    (data.find(
      (item) =>
        (item as WhItemResponse | RItemResponse)[field] ===
        props.dataItem[field]
    ) as WhItemResponse | RItemResponse);

  const editedCell =
    rowAvailable && props.field
      ? rowAvailable[props.field as keyof (WhItemResponse | RItemResponse)] !==
        props.dataItem[props.field]
      : false;

  return (
    <td title={title} style={props.style} className={className}>
      {renderComponent(
        props,
        value,
        customFields,
        inEdit && isEditable,
        editedCell
      )}
    </td>
  );
};

const handleOnChange = (
  e:
    | DropDownListChangeEvent
    | NumericTextBoxChangeEvent
    | React.ChangeEvent<HTMLInputElement>,
  props: GridCellProps,
  value: string | number | null
) => {
  const syntheticEvent = "syntheticEvent" in e ? e.syntheticEvent : e;
  props.onChange &&
    props.onChange({
      dataIndex: 0,
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent,
      value,
    });
};

const renderComponent = (
  props: GridCellProps,
  value: string,
  customFields?: FieldTypeProps,
  isEditable?: boolean,
  editedCell?: boolean
) => {
  const fieldType = customFields?.fieldType;

  const renderEditedCellIcon = () =>
    editedCell && (
      <span className="edited-cell">
        <SvgIcon icon={warningTriangleIcon} color="#1c7eff" />
      </span>
    );

  switch (fieldType) {
    case "dropdown": {
      if (isEditable) {
        return (
          <SelectDropdown
            code={customFields?.code}
            defaultValue={{ name: value }}
            onChange={(e) => handleOnChange(e, props, e.target.value?.name)}
          />
        );
      }
      return (
        <>
          {renderEditedCellIcon()}
          {value}
        </>
      );
    }
    case "date": {
      return parseDate(value);
    }
    case "numeric": {
      if (isEditable) {
        return (
          <NumericTextBox
            placeholder="please enter value"
            defaultValue={77}
            value={Number(value)}
            format="n2"
            onChange={(e) => handleOnChange(e, props, e.target.value)}
          />
        );
      }
      return (
        <>
          {renderEditedCellIcon()}
          {value}
        </>
      );
    }
    default: {
      if (isEditable) {
        return (
          <input
            className="k-input"
            data-prevent-selection="true"
            value={value}
            onChange={(e) => handleOnChange(e, props, e.target.value)}
          />
        );
      }
      return (
        <>
          {renderEditedCellIcon()}
          {value}
        </>
      );
    }
  }
};

export default TableColCell;
